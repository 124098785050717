(function() {

	/**
	 * Language select functionality
	 */
	var langBox = document.querySelector('.js-lang-box');
	if(langBox) {
		langBox.addEventListener('click', function(event) {
			event.stopPropagation();
			if(window.innerWidth < 1250) {
				langBox.classList.toggle('is-open');
			} else {
				langBox.classList.remove('is-open');
			}
		});
		document.body.addEventListener('click', function() {
			langBox.classList.remove('is-open');
		});
	}

	/**
	 * Enable/disable download options based on selected product ID
	 */
	var productId = document.querySelector('input[name="iso_link_id"]');
	if(productId) {
		var defaultSelected = false;
		var languageSelect = document.getElementById('language-select');
		languageSelect.addEventListener('change', function() {
			var radios = document.querySelectorAll('.js-radio');
			for(var r = 0; r < radios.length; r++) {
				if(radios[r].checked && isoLinks[radios[r].value][this.value]) {
					productId.value = isoLinks[radios[r].value][this.value].id;
					break;
				}
			}
		});
		for(var version in isoLinks) {
			var radio = document.querySelector('.js-radio[value="' + version + '"]');
			var allowedLanguages = [];
			for(var language in isoLinks[version]) {
				var option = document.querySelector('#language-select option[value="' + language + '"]');
				allowedLanguages.push(language);
				if(!defaultSelected) {
					option.selected = true;
					option.disabled = false;
					productId.value = isoLinks[version][language].id;
				}
			}
			radio.dataset.allowedLanguages = allowedLanguages.join(',');
			radio.disabled = false;
			radio.addEventListener('click', function() {
				if(this.checked) {
					var allowedLanguages = this.dataset.allowedLanguages.split(',');
					var options = languageSelect.options;
					for(var o = 0; o < options.length; o++) {
						options[o].disabled = true;
						options[o].selected = false;
					}
					for(var l = 0; l < allowedLanguages.length; l++) {
						var option = document.querySelector('#language-select option[value="' + allowedLanguages[l] + '"]');
						option.disabled = false;
						if(l == 0) {
							option.selected = true;
							productId.value = isoLinks[this.value][option.value].id;
						}
					}
				}
			});
			if(!defaultSelected) {
				radio.checked = true;
			}
			defaultSelected = true;
		}
	}

	/**
	 * Select download link on input focus
	 */
	var downloadInput = document.querySelector('.js-link-input');
	if(downloadInput) {
		downloadInput.addEventListener('click', function() {
			this.setSelectionRange(0, this.value.length);
		});
	}

	/**
	 * Copy input download link to clipboard
	 */
	var copyButton = document.querySelector('.js-copy-link');
	if(copyButton && downloadInput) {
		copyButton.addEventListener('click', function() {
			downloadInput.select();
			document.execCommand('copy');
			this.classList.add('is-copied');
		});
	}

	/**
	 * Go to the next product download options
	 */
	var nextButton = document.querySelector('.js-download-another');
	if(nextButton) {
		nextButton.addEventListener('click', function() {
			var productList = document.querySelector('.js-product-list');
			var activeItem = productList.querySelector('.is-active');
			var targetHref = '';
			if(activeItem.nextElementSibling) {
				targetHref = activeItem.nextElementSibling.querySelector('a').href;
			} else {
				targetHref = productList.firstElementChild.querySelector('a').href;
			}
			window.location.href = targetHref;
		});
	}

})();